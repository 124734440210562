<template>
  <div>
    <div class="login-register-area pt-100 pb-100">
      <div class="container">
        <div class="row">
          <div class="col-lg-2"></div>
          <div class="col-lg-8 col-md-12 ml-auto mr-auto">
            <div class="login-register-wrapper">
              <!-- <div class="login-register-tab-list nav">
              <router-link :to="{ name: 'Login' }" active-class="active">
                <h4>login</h4>
              </router-link>
              <router-link :to="{ name: 'Register' }" active-class="active">
                <h4>Registro</h4>
              </router-link>
            </div> -->
              <div class="tab-content">
                <div class="login-form-container">
                  <div class="login-register-form">
                    <v-alert
                      v-for="(error, idx) in getErrors"
                      :key="idx"
                      dense
                      text
                      type="error"
                    >
                      {{ error }}
                    </v-alert>
                    <v-alert
                      v-show="is_success"
                      dismissible
                      dense
                      text
                      type="success"
                      @input="closeAlert"
                    >
                      Revisa tu email para cambiar la contraseña
                    </v-alert>
                    <h2 class="">Recuperar contraseña</h2>
                    <form @submit.prevent="restPassword">
                      <input
                        v-model="user.email"
                        type="text"
                        name="email"
                        placeholder="Email"
                      />
                      <div class="float-right my-3">
                        <router-link class="" :to="{ name: 'Login' }"
                          >Iniciar sesión</router-link
                        >
                      </div>

                      <div class="button-box">
                        <v-btn
                          color="primary"
                          block
                          depressed
                          dark
                          large
                          :loading="isLoading"
                          type="submit"
                        >
                          <span class="">Enviar</span>
                        </v-btn>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import * as yup from "yup";

export default {
  name: "Login",
  created() {
    this.setErrors("clear");
  },
  data() {
    return {
      user: {
        email: "",
      },
      isLoading: false,
      is_success: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["getErrors"]),
  },
  watch: {
    is_success() {
      if (this.is_success) {
        setTimeout(() => {
          this.is_success = false;
        }, 5000);
      }
    },
  },

  methods: {
    ...mapActions("auth", ["recover_password", "setErrors"]),
    closeAlert() {
      this.is_success = false;
    },
    async validateForm() {
      this.setErrors("clear");
      let schemaUser = yup.object().shape({
        email: yup
          .string()
          .email("El email debe ser válido")
          .required("El email es requerido"),
      });

      try {
        await schemaUser.validate(this.user);
        return true;
      } catch (error) {
        this.setErrors(error.errors[0]);
        return false;
      }
    },
    async restPassword() {
      if (await this.validateForm()) {
        this.isLoading = true;
        await this.recover_password(this.user);
        if (this.getErrors.length == 0) {
          this.isLoading = false;
          this.is_success = true;
          this.user.email = "";
        }
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
@import url("../assets/css/login.css");
button {
  color: black;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
  padding: 6px 12px;
}
* {
  font-family: "Poppins", sans-serif !important;
}
a {
  font-size: 16px;
}
input:focus {
  border-color: #FAB848 !important;
}
</style>
